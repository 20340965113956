<script>
import Button from '../components/reusable/Button.vue';
import ProductsOverview from '../components/products/ProductsOverview.vue';
import ServicesOverview from '../components/service/ServicesOverview.vue';

export default {
	name: 'Home',
	components: {
    Button,
    ProductsOverview,
	ServicesOverview,
},
};
</script>

<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<section class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10">
			<!-- Banner left contents -->
			<div class="w-full md:w-1/3 text-left">
				<h1
					class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
				>
					生活+aiTeaTechで、<br/>
					かろやかに、美しく。
				</h1>
				<p
					class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
				>
					簡単なオンライン問診で、あなたに合う
					漢方薬を薬剤師がセレクトしてお届け。
					服用中の健康サポートで、あなたをもっと笑顔に。
				</p>
			</div>

			<!-- Banner right illustration -->
			<div class="w-full md:w-2/3 text-right float-right bg-local">
				<img src="@/assets/images/tea_20.jpeg" />
			</div>
		</section>

		<!-- Projects -->
		<ProductsOverview />

		<!-- Services -->
		<ServicesOverview />

		<!-- Load more projects button -->
		<div class="mt-10 sm:mt-20 flex justify-center">
			<a href="https://tea.aiteatech.com"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects"
			>
				<Button title="オンライン診断" />
			</a>
		</div>
	</div>
</template>

<style scoped></style>
