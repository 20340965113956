<script>
import Button from '../reusable/Button.vue';
import ServicesCommon from '../service/ServicesCommon.vue';
export default {
	name: 'ServicesOverview',
	components: {
		Button, 
		ServicesCommon,
	}
};
</script>

<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About details -->
		<div class="justify-self-center place-content-around px-80">
			<p
				class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
			>
				大地のエネルギーを吸収する根。
				そこから生まれる美しい花や鮮やかな果実。
				その生命力を閉じ込めた種。
				土の中ではゆっくりと時をかけて結晶が作り出され、
				海の中ではミネラルを豊富に含む牡蠣の殻が生まれます。
				漢方薬は、脈々と受け継がれてきたこの自然の力を組み合わせて、

				日本で完成しました。
				あなたのお悩みは何ですか？
			</p>
		</div>
	</div>

	<ServicesCommon />
	
	<!-- Load more projects button -->
	<div class="mt-10 sm:mt-20 flex justify-center">
		<router-link
			to="/service"
			class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
			aria-label="More Projects"
		>
			<Button title="サービス一覧" />
		</router-link>
	</div>
</template>
