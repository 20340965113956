<script>
export default {
	name: 'ServicesCommon',
	components: {
	}
};
</script>

<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About details -->
		<div class="justify-self-center place-content-around px-80">
			<p
				class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
			>
				<!-- Common services stuff -->
			</p>
		</div>
	</div>
</template>
